@import 'styles/includes';

.Text {
    margin: 40px 0;
    color: $colorBlue;

    @include media(s) {
        margin: 60px 0;
    }

    &--Gradient {
        margin: 0;
        padding: 40px 0;
        background: $colorGradient;

        @include media(s) {
            padding: 60px 0;
        }
    }

    &__Inner {
        @extend %container;
    }

    &__Container {
        @include media(s) {
            display: flex;
            margin-left: -12px;
            margin-right: -12px;
        }
    }

    &__Col {
        &:not(:last-child) {
            margin-bottom: 20px;
        }

        @include media(s) {
            padding: 0 12px;
            width: 33.33%;
        }
    }

    &__Title {
        @extend %h3;
        margin-bottom: 20px;

        &--Small {
            @extend %h4;
            padding-left: 44px;
            margin-bottom: 10px;
            position: relative;

            &::before {
                content: '';
                width: 12px;
                height: 10px;
                background-image: url('/img/check.svg');
                background-size: 12px 10px;
                position: absolute;
                top: 8px;
                left: 0;

                @include media(s) {
                    margin-right: 17px;
                    display: inline-block;
                    position: relative;
                    vertical-align: middle;
                    top: unset;
                }
            }

            @include media(s) {
                padding-left: 0;
            }
        }
    }

    &__Text {
        @extend %p;
        padding-left: 44px;
        color: $colorContrast;

        @include media(s) {
            padding-left: 0;
        }
    }
}
